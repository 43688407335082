import React from 'react';
import styled from 'styled-components';
import { styles, components } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Layout from '~/layout/LayoutMarketing';
import Illustration from '~/illustrations/Flag';

const Styled = styled.article`
  display: flex;
  flex-flow: column;
  .container {
    margin: 5rem auto;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 4rem;
    min-height: 60vh;
  }
  .container__right {
    display: flex;
    align-items: center;
  }
  h2,
  p {
    margin-bottom: 1rem;
  }
  iframe {
    border-radius: 5px;
    box-shadow: ${styles.BOX_SHADOW};
    height: 100%;
  }
  @media screen and (max-width: 800px) {
    .container {
      grid-template-columns: 1fr;
    }
    .container__left {
      max-width: 24rem;
      margin: auto;
    }
    .container__right__message {
      max-width: 40rem;
      margin: 0 auto;
    }
    iframe {
      min-height: 30rem;
    }
  }
  @media screen and (max-width: 500px) {
    .container__left {
      display: none;
    }
  }
`;

const RequestAccess = () => {
  return (
    <Layout title="Request Acces Sign Up">
      <Styled>
        <Container className="container">
          <div className="container__left">
            <Illustration width="100%" height="100%" />
          </div>
          <div className="container__right">
            <div className="container__right__message">
              <h2>You&#39;re Almost There!</h2>
              <p>
                We’d love to have you use Herald! However, as we’re still
                working towards a public launch, we need to make sure that
                Herald is ready for your team!{' '}
              </p>
              <components.Button
                style={{ marginTop: '3rem' }}
                onClick={() => {
                  const email = window.location.search.split('email=')[1];
                  window.open(
                    `https://airtable.com/embed/shrqfle1QhZ8dhBkU${
                      email ? `?prefill_Email=${email}` : ''
                    }`
                  );
                }}
                large={true}
              >
                Fill Out Form
              </components.Button>
            </div>
          </div>
        </Container>
      </Styled>
    </Layout>
  );
};

export default RequestAccess;
